.contact {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 5vw;
  box-sizing: border-box;
  justify-content: space-between;
  gap: 3vw;
}

.contact_reverse {
  flex-direction: row-reverse;
}

.contact__photo {
  width: 45vw;
  align-self: flex-start;
}

.contact__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact__name {
  font-size: 5vw;
  padding-bottom: 5vw;
}

.contact__link {
  font-family: 'Athiti', Arial, Helvetica, sans-serif;
  font-weight: 200;
  color: #282828;
  font-size: 4.5vw;
  padding-bottom: 5vw;
}

/* планшет вертикально */
@media (min-width: 768px) and (max-aspect-ratio: 1/1) {
  .contact__photo {
    display: none;
  }
  
  .contact_reverse {
    flex-direction: row;
  }

  .contact__name {
    font-size: 2vw;
  }
  
  .contact__link {
    font-size: 2vw;
  }
}
@font-face {
  font-family: 'AlegreyaSansSC';
  src: url('./AlegreyaSansSC-Light.woff2') format('woff2'),
       url('./AlegreyaSansSC-Light.woff') format('woff');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'AlegreyaSansSC';
  src: url('./AlegreyaSansSC-Regular.woff2') format('woff2'),
       url('./AlegreyaSansSC-Regular.woff') format('woff');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Athiti';
  src: url('./Athiti-ExtraLight.woff2') format('woff2'),
       url('./Athiti-ExtraLight.woff') format('woff');
  font-weight: 200; /* Extra-Light */
  font-style: normal;
}

.commercial-sample-horizontal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.commercial-sample-horizontal__section {
  display: flex;
  flex-direction: column;
}

.commercial-sample-horizontal__title {
  font-size: 4vw;
  color: #282828;
  font-variant-numeric: lining-nums;
}

.commercial-sample-horizontal__image-magnum,
.commercial-sample-horizontal__image-second {
  width: 29vw;
}

.commercial-sample-horizontal__image-first {
  width: 34vw;
}

.commercial-sample-horizontal__title-section,
.commercial-sample-horizontal__arrow-section {
  justify-content: space-between;
}

.commercial-sample-horizontal__link {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.commercial-sample-horizontal__link-arrow {
  width: 11vw;
  padding-top: 2vh;
}

/* планшет горизонтально */
@media (min-height: 768px) and (min-aspect-ratio: 1/1) {
  .commercial-sample-horizontal__title {
    font-size: 3.1vw;
  }

  .commercial-sample-horizontal__image-first {
    width: 29vw;
  }

  .commercial-sample-horizontal__section {
    gap: 1vh;
  }

  .commercial-sample-horizontal__link-arrow {
    width: 7vw;
    padding-top: 1.5vh;
  }
}

/* десктоп */
@media (min-width: 1440px) and (min-aspect-ratio: 1/1) {
  .commercial-sample-horizontal__title {
    font-size: 3vw;
  }

  .commercial-sample-horizontal__image-magnum,
  .commercial-sample-horizontal__image-second {
    width: 24vw;
  }

  .commercial-sample-horizontal__link-arrow {
    padding: 1.5vh 0vw 1vh 1vw;
  }
}
.contacts {
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8vh 3vw 0vw;
  align-items: flex-start;
}

.contacts__title {
  font-size: 10vw;
  font-weight: 400;
  align-self: flex-start;
  padding-bottom: 10vw;
}

.contacts__image {
  display: none;
}

.contacts__mobile {
  box-sizing: border-box;
}

.contacts__regular {
  display: none;
}

@media (max-width: 319px) and (max-aspect-ratio: 1/1) {
  .contacts {
    padding-top: 28vh;
  }
}

@media (max-width: 319px) and (max-aspect-ratio: 2/3) {
  .contacts {
    padding-top: 17vh;
  }
}

@media (max-width: 319px) and (max-aspect-ratio: 1/2) {
  .contacts {
    padding-top: 14vh;
  }
}

@media (min-width: 320px) and (max-aspect-ratio: 1/1) {
  .contacts {
    padding-top: 22vh;
  }
}

@media (min-width: 320px) and (max-aspect-ratio: 2/3) {
  .contacts {
    padding-top: 18vh;
  }
}

@media (min-width: 320px) and (max-aspect-ratio: 1/2) {
  .contacts {
    padding-top: 14vh;
  }
}

/* планшет вертикально */
@media (min-width: 768px) and (max-aspect-ratio: 1/1) {
  .contacts {
    padding: 22vh 9.5vw 0vw;
  }

  .contacts__container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 3vw;
  }

  .contacts__title {
    font-size: 4.2vw;
    padding-bottom: 3vw;
  }

  .contacts__image {
    display: block;
    width: 39vw;
  }

  .contacts__mobile {
    display: none;
  }

  .contacts__regular {
    display: flex;
    flex-direction: column;
    padding-top: 6vw;
  }
}

@media (min-width: 768px) and (max-aspect-ratio: 4/5) {
  .contacts {
    padding-top: 24vh;
  }
}

@media (min-width: 768px) and (max-aspect-ratio: 2/3) {
  .contacts {
    padding-top: 20vh;
  }
}

@media (min-width: 768px) and (max-aspect-ratio: 1/2) {
  .contacts {
    padding-top: 16vh;
  }
}

/* мобильный горизонтально */
@media (max-height: 767px) and (min-aspect-ratio: 1/1) {
  .contacts {
    padding: 24vh 2vw 5vh;
  }

  .contacts__container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5vw;
  }

  .contacts__title {
    font-size: 4.2vw;
    padding-bottom: 3vw;
  }

  .contacts__image {
    display: block;
    width: 20vw;
  }

  .contacts__mobile {
    display: none;
  }

  .contacts__regular {
    display: flex;
    padding-top: 0;
  }
}

@media (max-height: 767px) and (min-aspect-ratio: 6/5) {
  .contacts {
    padding-top: 20vh;
  }
}

@media (max-height: 767px) and (min-aspect-ratio: 4/3) {
  .contacts {
    padding-top: 24vh;
  }
}

@media (max-height: 767px) and (min-aspect-ratio: 3/2) {
  .contacts {
    padding-top: 26vh;
  }
}

@media (max-height: 767px) and (min-aspect-ratio: 2/1) {
  .contacts {
    padding-top: 27vh;
  }
}

@media (max-height: 767px) and (min-aspect-ratio: 3/1) {
  .contacts {
    padding-top: 40vh;
  }
}

/* планшет горизонтально */
@media (min-height: 768px) and (min-aspect-ratio: 1/1) {
  .contacts {
    padding: 20vh 4vw 5vh;
    gap: 2.5vh;
  }

  .contacts__container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2.5vw;
  }

  .contacts__title {
    font-size: 3.2vw;
    padding: 0;
  }

  .contacts__mobile {
    display: none;
  }

  .contacts__image {
    display: block;
    width: 36.6vw;
  }

  .contacts__regular {
    display: flex;
  }
}

@media (min-height: 768px) and (min-aspect-ratio: 6/5) {
  .contacts {
    padding-top: 28vh;
  }
}

@media (min-height: 768px) and (min-aspect-ratio: 4/3) {
  .contacts {
    padding-top: 18vh;
  }
}

/* десктоп */
@media (min-height: 768px) and (min-width: 1440px) and (min-aspect-ratio: 1/1) {
  .contacts {
    padding: 20vh 9.5vw 0vh;
    gap: 5vh;
  }

  .contacts__title {
    font-size: 3.3vw;
    padding: 0;
  }

  .contacts__image {
    width: 26vw;
  }

  .contacts__regular {
    display: flex;
  }

  .contacts__container {
    gap: 15.2vw;
  }
}

@media (min-height: 768px) and (min-width: 1440px) and (min-aspect-ratio: 4/3) {
  .contacts {
    padding-top: 20vh;
  }
}

@media (min-height: 768px) and (min-width: 1440px) and (min-aspect-ratio: 3/2) {
  .contacts {
    padding-top: 22vh;
  }
}

@media (min-height: 768px) and (min-width: 1440px) and (min-aspect-ratio: 2/1) {
  .contacts {
    padding-top: 24vh;
  }

  .contacts__image {
    width: 47vh;
  }
}

@media (min-height: 768px) and (min-width: 1440px) and (min-aspect-ratio: 3/1) {
  .contacts {
    padding-top: 26vh;
  }
}
.preloader {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.preloader--app {
  width: 100vw;
  height: 100vh;
}

.preloader--gallery {
  width: 100%;
  flex-grow: 1;
}

.preloader__image {
  max-width: 100%;
  max-height: 40vh;
}

.preloader__image--gallery {
  width: 20vw;
  height: 20vw;
}

#rotating-ring {
  transform-origin: 32px 36px; /* Центр вращения */
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
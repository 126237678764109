.navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: -101vw;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  transition: right 0.5s ease;
}

.navbar_opened {
  right: 0;
}

.navbar__container {
  background-color: #fff;
  height: 100%;
  width: 72vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 6vw;
  opacity: 0.8;
  z-index: 20;
}

.navbar__links-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8vw;
}

.navbar__title-subsection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 4vw 3vw 7vw 0;
}

.navbar__close-icon {
  width: 6vw;
}

.navbar__title {
  font-size: 5vw;
  font-weight: 400;
  line-height: 0.9;
  letter-spacing: -0.5px;
}

.navbar__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 6vw;
  font-weight: 400;
  line-height: 0.9;
}

.navbar__instagram-link {
  margin: 0 0 8vh 0;
  width: 45vw;
  font-size: 6.2vw;
}

/* мобильный горизонтально */
@media (max-height: 767px) and (min-aspect-ratio: 1/1) {
  .navbar__container {
    width: 50vw;
    padding-left: 4vw;
  }

  .navbar__title {
    font-size: 3.2vw;
  }

  .navbar__links-section {
    gap: 7vh;
  }

  .navbar__link {
    font-size: 3vw;
  }

  .navbar__title-subsection {
    padding: 5vh 4vh 0vh 0vh;
  }

  .navbar__close-icon {
    width: 3vw;
  }

  .navbar__instagram-link {
    margin: 0 0 6vh 0;
    font-size: 3.5vw;
  }
}
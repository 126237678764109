.contact-info {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: auto;
  gap: 0vw;
  padding-top: 8vw;
}

.contact-info__section-title {
  color: #282828;
  font-size: 5vw;
}

.contact-info__photographer-section--title {
  grid-column: 1;
  grid-row: 1;
}

.contact-info__links-section {
  display: flex;
  flex-direction: column;
  padding-bottom: 2vw;
}

.contact-info__link {
  font-family: 'Athiti', Arial, Helvetica, sans-serif;
  font-weight: 200;
  color: #A2937C;
  font-size: 4.7vw;
  margin: 0 0 2vw;
  line-height: 1.4;
}

.contact-info__horizontal-line {
  grid-column: 1 / -1;
  grid-row: 2;
  width: 100%;
  border: none;
  height: 1px;
  background-color: #F7F7F7;
  margin: 0 0 6vw;
}

.contact-info__agent-section--title {
  grid-column: 1;
  grid-row: 3;
  line-height: 0.9;
}

.contact-info__link--contacts {
  color: #282828;
}

/* планшет вертикально */
@media (min-width: 768px) and (max-aspect-ratio: 1/1) {
  .contact-info {
    width: 40vw;
    padding-top: 0;
  }

  .contact-info__section-title {
    font-size: 2.5vw;
  }

  .contact-info__links-section {
    padding: 0;
  }

  .contact-info__link {
    font-size: 2vw;
    line-height: 0.9;
  }

  .contact-info__horizontal-line {
    margin: 0 0 2vw;
  }

  .contact-info__horizontal-line--contacts {
    margin: 2vw 0;
  }
}

/* мобильный горизонтально */
@media (max-height: 767px) and (min-aspect-ratio: 1/1) {
  /* стили для footer
  не подписаны, будут идти как по-умолчанию */

  .contact-info {
    padding-top: 0vh;
    grid-template-columns: 30% 1fr;
    column-gap: 19vw;
    row-gap: 2vh;
  }

  .contact-info__section-title {
    font-size: 3vw;
  }

  .contact-info__links-section {
    padding: 0;
  }

  .contact-info__link {
    font-size: 3vw;
    margin: 0 0 6vh;
    line-height: 0.9;
  }

  .contact-info__horizontal-line {
    margin: 0 0 2vh;
  }

  .contact-info--contacts {
    padding-top: 0;
    grid-template-columns: 20vw 1fr;
    column-gap: 5vw;
  }

  .contact-info__section-title--contacts {
    font-size: 3vw;
  }

  .contact-info__link--contacts {
    font-size: 2.5vw;
    margin: 0 0 5vh;
  }
}

/* планшет горизонтально */
@media (min-height: 768px) and (min-aspect-ratio: 1/1) {

  /* footer */
  .contact-info--footer {
    padding-top: 0;
    grid-template-columns: 14vw 1fr;
    column-gap: 3vw;
    row-gap: 2vh;
  }

  .contact-info__section-title--footer {
    font-size: 2.2vw;
  }

  .contact-info__link--footer {
    font-size: 1.7vw;
    line-height: 0.9;
    margin: 0 0 0vh;
  }

  .contact-info__links-section--footer {
    padding-bottom: 0vw;
    justify-content: space-between;
    gap: 2vh;
  }

  .contact-info__horizontal-line--footer {
    margin: 0 0 0vw;
  }

  /* contacts */
  .contact-info--contacts {
    padding-top: 4.5vh;
    grid-template-columns: 52% 1fr;
    row-gap: 4vh;
    column-gap: 0;
  }

  .contact-info__section-title--contacts {
    font-size: 3vw;
  }

  .contact-info__link--contacts {
    font-size: 2.5vw;
    margin: 0 0 1vh;
  }

  .contact-info__links-section--contacts {
    padding-bottom: 0vw;
  }

  .contact-info__horizontal-line--contacts {
    margin: 0 0 0vw;
  }
}

/* десктоп */
@media (min-width: 1440px) and (min-aspect-ratio: 1/1) {

  /* стили для footer
  без селектора, будут идти как по-умолчанию */
  .contact-info__link {
    font-size: 1.3vw;
  }

  .contact-info__links-section {
    gap: 3vh;
  }

  /* contacts */
  .contact-info--contacts {
    padding-top: 8.5vh;
    column-gap: 1vw;
    row-gap: 5vh;
  }

  .contact-info__section-title--contacts {
    font-size: 2.3vw;
  }

  .contact-info__links-section--contacts {
    gap: 3vh;
  }

  .contact-info__link--contacts {
    margin: 0 0 0vh;
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  background-color: #fff;
  transition: transform 0.3s ease;
}

.header_hidden {
  transform: translateY(-100%);
}

.header_undisplay {
  display: none;
}

.header__title-block {
  width: 100%;
  box-sizing: border-box;
  padding: 5vw 3vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5vw 3vw 3vw;
}

.header__title {
  font-size: 4.7vw;
  font-weight: 400;
  text-decoration: none;
  color: #282828;
}

.header__menu-icon {
  width: 8vw;
}

.header__horizontal-line {
  width: 100%;
  border: none;
  margin: 0;
  height: 1px;
  background-color: #F7F7F7;
}

/* планшет вертикально */
@media (min-width: 768px) and (max-aspect-ratio: 1/1) {
  .header__menu-icon {
    display: none;
  }

  .header__title-block {
    padding: 2vw 10vw 2vw;
  }

  .header__title {
    font-size: 3vw;
  }
}

/* мобильный горизонтально */
@media (max-height: 767px) and (min-aspect-ratio: 1/1) {
  .header__title {
    font-size: 2.8vw;
  }

  .header__title-block {
    padding: 3.3vh 2vw 2.2vh;
  }

  .header__menu-icon {
    width: 5vw;
  }
}

/* планшет горизонтально */
@media (min-height: 768px) and (min-aspect-ratio: 1/1) {
  .header__menu-icon {
    display: none;
  }

  .header__title {
    font-size: 2.35vw;
  }

  .header__title-block {
    padding: 2.2vh 4vw 2.2vh;
  }
}

/* десктоп */
@media (min-height: 768px) and (min-width: 1440px) and (min-aspect-ratio: 1/1) {
  .header__title-block {
    padding: 2.2vh 9.5vw 2.2vh;
  }

  .header__title {
    font-size: 2.23vw;
  }
}
.commercial {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff;
  padding: 8vh 3vw 10vw;
}

.commercial__samples {
  width: 100%;
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
}

.commercial__samples--three-in-line {
  display: none;
  width: 100%;
  flex-direction: column;
  gap: 11vh;
}

/* мобильный вертикально */
@media (max-width: 319px) and (max-aspect-ratio: 1/1) {
  .commercial {
    padding-top: 18vh;
  }
}

@media (max-width: 319px) and (max-aspect-ratio: 2/3) {
  .commercial {
    padding-top: 12vh;
  }
}

@media (max-width: 319px) and (max-aspect-ratio: 1/2) {
  .commercial {
    padding-top: 10vh;
  }
}

@media (min-width: 320px) and (max-aspect-ratio: 1/1) {
  .commercial {
    padding-top: 18vh;
  }
}

@media (min-width: 320px) and (max-aspect-ratio: 2/3) {
  .commercial {
    padding-top: 12vh;
  }
}

@media (min-width: 320px) and (max-aspect-ratio: 1/2) {
  .commercial {
    padding-top: 10vh;
  }
}

/* планшет вертикально */
@media (min-width: 768px) and (max-aspect-ratio: 1/1) {
  .commercial {
    padding: 12vh 10vw 3vw;
  }
}

@media (min-width: 768px) and (max-aspect-ratio: 4/5) {
  .commercial {
    padding-top: 8vh;
  }
}

@media (min-width: 768px) and (max-aspect-ratio: 2/3) {
  .commercial {
    padding-top: 6vh;
  }
}

@media (min-width: 768px) and (max-aspect-ratio: 1/2) {
  .commercial {
    padding-top: 5vh;
  }
}

/* мобильный горизонтально */
@media (max-height: 767px) and (min-aspect-ratio: 1/1) {
  .commercial {
    padding: 18vh 2vw 8vh;
  }

  .commercial__samples {
    display: none;
  }

  .commercial__samples--three-in-line {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 11vh;
  }
}

@media (max-height: 767px) and (min-aspect-ratio: 6/5) {
  .commercial {
    padding-top: 20vh;
  }
}

@media (max-height: 767px) and (min-aspect-ratio: 4/3) {
  .commercial {
    padding-top: 24vh;
  }
}

@media (max-height: 767px) and (min-aspect-ratio: 3/2) {
  .commercial {
    padding-top: 26vh;
  }
}

@media (max-height: 767px) and (min-aspect-ratio: 2/1) {
  .commercial {
    padding-top: 30vh;
  }
}

@media (max-height: 767px) and (min-aspect-ratio: 3/1) {
  .commercial {
    padding-top: 40vh;
  }
}

/* планшет горизонтально */
@media (min-height: 768px) and (min-aspect-ratio: 1/1) {
  .commercial__samples {
    display: none;
  }

  .commercial {
    padding: 18vh 4vw 8vh;
  }

  .commercial__samples--three-in-line {
    display: flex;
    gap: 8vh;
  }
}

@media (min-height: 768px) and (min-aspect-ratio: 6/5) {
  .commercial {
    padding-top: 20vh;
  }
}

@media (min-height: 768px) and (min-aspect-ratio: 4/3) {
  .commercial {
    padding-top: 18vh;
  }
}

/* десктоп */
@media (min-height: 768px) and (min-width: 1440px) and (min-aspect-ratio: 1/1) {
  .commercial {
    padding: 18vh 9.7vw 8vh;
  }

  .commercial__samples--three-in-line {
    display: flex;
  }

  .commercial__samples {
    display: none;
  }
}

@media (min-height: 768px) and (min-width: 1440px) and (min-aspect-ratio: 4/3) {
  .commercial {
    padding-top: 20vh;
  }
}

@media (min-height: 768px) and (min-width: 1440px) and (min-aspect-ratio: 3/2) {
  .commercial {
    padding-top: 22vh;
  }
}

@media (min-height: 768px) and (min-width: 1440px) and (min-aspect-ratio: 2/1) {
  .commercial {
    padding-top: 24vh;
  }
}

@media (min-height: 768px) and (min-width: 1440px) and (min-aspect-ratio: 3/1) {
  .commercial {
    padding-top: 26vh;
  }
}
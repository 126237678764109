.blur-overlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 18;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  pointer-events: none;
}

.blur-overlay--navbar {
  display: block;
}

.blur-overlay--popup {
  display: block;
  background-color: rgba(0, 0, 0, 0.8);
}
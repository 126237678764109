.footer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1vw 3vw;
  border-top: 1px solid #F7F7F7;
}

.footer_undisplay {
  display: none;
}

.footer__title {
  color: #282828;
  font-size: 12.5vw;
  line-height: 1.1;
}

.footer__sections {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: auto;
  gap: 0vw;
  padding-top: 8vw;
}

/* планшет вертикально */
@media (min-width: 768px) and (max-aspect-ratio: 1/1) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
    padding: 2.5vw 9.5vw;
  }

  .footer__title {
    font-size: 4.2vw;
  }
}

/* мобильный горизонтально */
@media (max-height: 767px) and (min-aspect-ratio: 1/1) {
  .footer {
    padding: 1vh 2vw 5vh;
    gap: 9vh;
  }

  .footer__title {
    font-size: 8.2vw;
  }
}

/* планшет горизонтально */
@media (min-height: 768px) and (min-aspect-ratio: 1/1) {
  .footer {
    padding: 1vh 7vw 7vh 10vw;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer__title {
    font-size: 4vw;
  }
}

/* десктоп */
@media (min-width: 1440px) and (min-aspect-ratio: 1/1) {
  .footer {
    padding: 1vh 10vw 7vh;
  }
  .footer__title {
    font-size: 4.4vw;
  }
}
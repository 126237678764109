@import url(./vendor/normalize.css);
@import url(./fonts/fonts.css);

html {
  scroll-behavior: smooth;
  line-height: 1;
}

body.no-scroll {
  overflow: hidden;
}

main {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
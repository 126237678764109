.navpanel {
  width: 50vw;
  align-self: flex-end;
  box-sizing: border-box;
  justify-content: flex-end;
  padding: 3vw 10vw 0 0;
  gap: 3vw;
  z-index: 2;
  display: none;
}

.navpanel__navigation-link {
  color: #fff;
  font-weight: 300;
  font-size: 2.7vw;
}

.navpanel--header {
  padding: 0;
  align-self: center;
}

.navpanel__navigation-link--header {
  color: #282828;
}

/* планшет вертикально */
@media (min-width: 768px) and (max-aspect-ratio: 1/1) {
  .navpanel {
    display: flex;
  }
}

/* мобильный горизонтально */
@media (max-height: 767px) and (min-aspect-ratio: 1/1) {}

/* планшет горизонтально */
@media (min-height: 768px) and (min-aspect-ratio: 1/1) {
  .navpanel {
    display: flex;
    padding: 2.5vw 4vw 0 0;
    gap: 4.7vw;
  }

  .navpanel__navigation-link {
    color: #282828;
    font-size: 2vw;
  }

  .navpanel--header {
    padding: 0;
  }
}

/* десктоп */
@media (min-width: 1440px) and (min-aspect-ratio: 1/1) {
  .navpanel {
    padding: 3vh 9.8vw 0 0;
    gap: 6.2vw;
  }

  .navpanel--header {
    padding: 0;
    gap: 81px;
  }

  .navpanel__navigation-link {
    font-size: 1.4vw;
  }
}
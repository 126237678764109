.navlinks {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 4.5vw 3vw 3vw;
  z-index: 9;
}

.navlinks__link {
  display: flex;
  flex-direction: row;
}

.navlinks__link_text {
  font-weight: 300;
  color: #282828;
  font-size: 4.2vw;
  vertical-align: middle;
  text-align: center;
}

.navlinks__breakdot {
  margin: 0 4.3vw;
  font-size: 7vw;
  text-align: center;
  line-height: 0.9;
}

.navlinks_hidden {
  display: none;
}

/* планшет вертикально */
@media (min-width: 768px) and (max-aspect-ratio: 1/1) {
  .navlinks {
    padding: 2vw 10vw 2vw;
  }

  .navlinks__link_text {
    font-size: 1.8vw;
  }

  .navlinks__breakdot {
    margin: 0 1.3vw;
    font-size: 3vw;
  }
}

/* мобильный горизонтально */
@media (max-height: 767px) and (min-aspect-ratio: 1/1) {
  .navlinks {
    padding: 2.2vh 2.2vw;
  }

  .navlinks__link_text {
    font-size: 1.7vw;
    letter-spacing: 1.5px;
  }

  .navlinks__breakdot {
    margin: 0 2.3vw;
    font-size: 4vw;
  }
}

/* планшет горизонтально */
@media (min-height: 768px) and (min-aspect-ratio: 1/1) {
  .navlinks {
    padding: 4vh 4.1vw 3.5vh;
  }

  .navlinks__link_text {
    font-size: 1.3vw;
  }

  .navlinks__breakdot {
    margin: 0 1.4vw;
    font-size: 1.5vw;
  }
}

/* десктоп */
@media (min-height: 768px) and (min-width: 1440px) and (min-aspect-ratio: 1/1) {
  .navlinks {
    padding: 4vh 9.7vw 3.5vh;
  }

  .navlinks__link_text {
    font-size: 1.1vw;
    letter-spacing: 1px;
  }
}
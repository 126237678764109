.photo-popup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 18;
}

/* .photo-popup--final {
  justify-content: flex-end;
}

.photo-popup--start {
  justify-content: flex-start;
} */

.photo-popup__sideblock {
  flex-direction: row;
}

.photo-popup__text {
  color: #fff;
  font-weight: 400;
  font-size: 5vw;
  z-index: 19;
  width: 17vw;
  padding: 0 2vw;
}

.photo-popup__start-marker {
  text-align: start;
}

.photo-popup__end-marker {
  text-align: end;
}

.photo-popup__container {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 19;
}

.photo-popup__image {
  max-width: 95vw;
  max-height: 95vh;
  transition: transform 0.2s ease-out;
}

.photo-popup__image--swipe-left {
  transform: translateX(-100vw);
}

.photo-popup__image--swipe-right {
  transform: translateX(100vw);
}

.photo-popup__turn-arrow {
  z-index: 20;
  width: 3vw;
  padding: 0 2vw;
  display: none;
}

.photo-popup__turn-arrow--left {
  rotate: 180deg;
}

@media (orientation: landscape) {
  .photo-popup__turn-arrow {
    display: block;
  }

  .photo-popup__image {
    max-width: 86vw;
  }

  .photo-popup__turn-arrow--left_hidden {
    display: none;
  }

  .photo-popup__turn-arrow--right_hidden {
    display: none;
  }
}
.main {
  width: 100%;
  flex-grow: 1;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.main__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../../images/main-banner-mobile.jpg);
  background-size: cover;
  background-position: 51%;
  opacity: 0.6;
}

.main__content {
  width: 100%;
  display: flex;
  z-index: 2;
  box-sizing: border-box;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4vh 4vh;
  gap: 1.6vw;
  flex-grow: 0;
}

.main__title {
  color: #fff;
  font-weight: 400;
  padding: 0;
  font-size: 10vw;
  font-weight: 400;
  line-height: 0.9;
}

.main__subtitle {
  color: #fff;
  font-weight: 300;
  line-height: 0.9;
  font-size: 4vw;
}

.main__menu-icon {
  width: 8vw;
  z-index: 2;
  align-self: flex-end;
  padding: 6vw 3vw;
}

.main__menu-icon--hidden {
  visibility: hidden;
}

/* планшет вертикально */
@media (min-width: 768px) and (max-aspect-ratio: 1/1) {
  .main__menu-icon {
    display: none;
  }

  .main__content {
    flex-direction: column;
    padding: 27.5vh 4vh;
    align-items: center;
    gap: 2vw;
  }

  .main__title {
    font-size: 7.3vw;
    opacity: 0.8;
  }
}

/* мобильный горизонтально */
@media (max-height: 767px) and (min-aspect-ratio: 1/1) {
  .main {
    justify-content: flex-start;
  }

  .main__background {
    background-position: 49% 9%;
  }

  .main__menu-icon {
    width: 6vw;
    padding: 4vw 2vw;
  }

  .main__content {
    width: 50vw;
    padding: 29vh 3.5vh 0vh;
    align-self: flex-start;
  }

  .main__subtitle {
    line-height: 0.9;
    font-size: 2.3vw;
    letter-spacing: 1.2px;
  }

  .main__title {
    font-size: 6.5vw;
  }
}

/* планшет горизонтально */
@media (min-height: 768px) and (min-aspect-ratio: 1/1) {
  .main {
    gap: 42vh;
    justify-content: flex-start;
  }

  .main__background {
    background-image: url(../../images/main-banner-desktop.jpg);
    opacity: 0.8;
  }

  .main__menu-icon {
    display: none;
  }

  .main__content {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0vh 3.5vh 0vh;
    gap: 1.6vh;
  }

  .main__title {
    font-size: 6.2vw;
  }
}

/* десктоп */
@media (min-width: 1200px) and (min-aspect-ratio: 1/1) {
  .main__content {
    gap: 2vh;
  }

  .main__title {
    font-size: 5.55vw;
  }

  .main__subtitle {
    font-size: 2.2vw;
  }
}
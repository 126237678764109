.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #fff;
}

.app-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff;
}

/* класс вводится в ответ на существующий 
на данный момент header с абсолютным позиционированием.
Класс предотвращает перекрытие шапкой содержимого секций,
на которых шапка отображается 
не применяется в commercials.css тк создан на его основе*/
.app-section {
  padding-top: 10vw;
}

.app-text {
  font-family: 'AlegreyaSansSC', Arial, Helvetica, sans-serif;
  margin: 0;
  color: #000;
  line-height: 1;
}

.app-link {
  text-decoration: none;
  transition: opacity 0.3s;
}

.app-link:hover {
  opacity: 0.5;
  cursor: pointer;
}

.app__preloader{
  width: 100vw;
  height: 100vh;
  background-color: #200000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app__preloader-text{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12vw;
  color: #bbbbbb;
}

.app__preloader-animation{
  width: 50vw;
  margin-top: 5vh;
}

/* класс вводится в ответ на существующий 
на данный момент header с абсолютным позиционированием.
Класс предотвращает перекрытие шапкой содержимого секций,
на которых шапка отображается
не применяется в commercials.css тк создан на его основе*/
@media (max-width: 550px) {
  .app-section {
    padding-top: 26vw;
  }
}